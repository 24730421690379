import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import PortfolioRoll from '../components/PortfolioRoll'
import FullWidthImage from '../components/FullWidthImage'

// eslint-disable-next-line
export const IndexPageTemplate = ({ image, heading, subheading, about }) => {
  const heroImage = getImage(image) || image

  return (
    <>
      <FullWidthImage img={heroImage} title={heading} subheading={subheading} />
      <section className="about">
        <h1>{about.title}</h1>
        <h3>{about.intro}</h3>
        <p>{about.description}</p>
      </section>
      <section className="blog">
        <h3>Latest stories</h3>
        <BlogRoll />
        <Link className="btn large" to="/blog">
          Blog Entries
        </Link>
      </section>
      <section className='portfolio'>
        <h3>Portfolio</h3>
        <PortfolioRoll />
      </section>
    </>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  about: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
        about={frontmatter.about}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        about {
          title
          intro
          description
        }
      }
    }
  }
`
